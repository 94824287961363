<template>
	<div class="h-100vh">
		<div class="row m-0 h-100">
			<div class="col-12 col-lg-5 ">
				<Login v-if="getRouteName === 'Login'" />
				<Register v-if="getRouteName === 'Register'" />
				<ResetPassword v-if="getRouteName === 'ResetPassword'" />
				<ChangePassword v-if="getRouteName === 'ChangePassword'" />
			</div>
			<div class="col-12 col-lg-7 p-0 d-none d-lg-block">
				<div class="img-wrapper h-100">
					<img src="@/assets/Main-Background.png" alt="" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue'
	import { useRoute } from 'vue-router'
	import Login from '../../components/auth/Login'
	import Register from '../../components/auth/Register'
	import ResetPassword from '../../components/auth/ResetPassword'
	import ChangePassword from '../../components/auth/ChangePassword'

	export default {
		setup() {
			const getRouteName = computed(() => useRoute().name)
			return {
				Login,
				Register,
				ResetPassword,
				ChangePassword,
				getRouteName,
			}
		},
	}
</script>

<style lang="scss">
	.img-wrapper {
		img {
			max-height: 100vh;
			max-width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.h-100vh {
		height: 100vh;
	}

	@media only screen and (max-width: 992px) {
		// dont ask
		body {
			height: 100vh;
			#app .row.m-0 {
				height: 100%;
			}
		}
	}
</style>
