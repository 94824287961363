<template>
	<Layout>
		<template v-slot:text>
			<SetMeta :API="false" Title="Register" />
			<!-- The code below goes into the text slot -->
			<p>Already got an account? - <a href="/login">Login</a></p>
		</template>
	</Layout>
</template>

<script>
import SetMeta from '@/components/_Meta/SetData'
	import Layout from './snippets/Layout'
	export default {
		setup() {
			return { SetMeta, Layout }
		},
	}
</script>

<style lang="scss">
	a {
		color: #293370;
	}
	.width {
		width: 400px;
		margin: 0px auto;
	}
</style>
