<template>
	<Layout>
		<template v-slot:text>
			<SetMeta :API="false" Title="Reset Password" />
			<p>Don't have an account? - <a href="/register">Register</a></p>
		</template>
	</Layout>
</template>

<script>
import SetMeta from '@/components/_Meta/SetData'
import Layout from './snippets/Layout'
export default {
	setup() {
		return { SetMeta, Layout }
	},
}
</script>

<style lang="scss">
a {
	color: #293370;
}
.width {
	width: 400px;
	margin: 0px auto;
}
</style>
