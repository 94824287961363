<template>
	<div class="h-100 width col-12">
		<div class="d-flex flex-column justify-content-between pt-5 pb-5  h-100">
			<div class="logo-wrapper mb-4 text-center">
				<img alt="Vue logo" src="../../../assets/logos/logoblue.png" />
			</div>

			<div class="form-wrapper">
				<Form />
			</div>

			<div class="register text-center">
				<slot name="text"></slot>
			</div>
		</div>
	</div>
</template>

<script>
	import Form from './Form'
	export default {
		setup() {
			return { Form }
		},
	}
</script>
