<template>
	<div>
		<form @submit.prevent="register" v-if="currentRouteName === 'Register'" action="" class="d-flex flex-column">
			<div class="d-grid">
				<label for="FullName">Full Name*</label>
				<input v-model="registerUserData.FullName" type="text" name="FullName" id="FullName" required />

				<label for="register-email">Email*</label>
				<input v-model="registerUserData.Email" type="email" name="register-email" id="register-email" required />

				<label for="Contact Number">Contact Number*</label>
				<input v-model="registerUserData.phoneNumber" type="number" name="Contact Number" id="Contact Number" required />

				<label for="password">Password*</label>
				<input v-model="registerUserData.Password" type="Password" name="Password" id="Password" required />

				<label for="companyName">Company Name (Optional)</label>
				<input v-model="registerUserData.CompanyName" type="CompanyName" name="CompanyName" id="CompanyName" />
				<div v-if="errors.length" class="alert alert-danger" role="alert">
					<p v-for="x in errors" :key="x">{{ x }}</p>
				</div>
				<button type="submit">Start your free trial</button>
			</div>
		</form>

		<form @submit.prevent="login" v-if="currentRouteName === 'Login'" action="" class="d-flex flex-column">
			<label for="login-email">Email</label>
			<input v-model="loginUserData.Email" type="email" name="login-email" id="login-email" required />

			<label for="password">Password</label>
			<input v-model="loginUserData.Password" type="password" name="password" id="password" required />
			<div v-if="errors.length" class="alert alert-danger" role="alert">
				<p v-for="x in errors" :key="x.id">{{ x.message }}</p>
			</div>
			<button type="submit">Login</button>
			<div class="row align-items-center">
				<div class="col-6 col-lg-6">
					<label for="remembeerMe" class="d-flex mb-0">
						<input type="checkbox" name="" id="" />
						<p>Remember me</p>
					</label>
				</div>
				<div class="col-6 col-lg-6 text-right">
					<p><a href="/reset-password">forgot password</a></p>
				</div>
			</div>
		</form>

		<form @submit.prevent="resetPassword" v-if="currentRouteName === 'ResetPassword'" action="" class="d-flex flex-column">
			<label for="resetPassword">Email</label>
			<input v-model="pwResetBody.email" type="email" name="resetPassword" id="resetPassword" required />
			<div v-if="success.length" class="alert alert-success" role="alert">
				<p v-for="x in success" :key="x.id">Success! {{ x }}</p>
			</div>
			<button v-else type="submit">Reset Password</button>
		</form>

		<form @submit.prevent="newPasswordSubmit" v-if="currentRouteName === 'ChangePassword'" action="" class="d-flex flex-column">
			<label for="newPassword">Password</label>
			<input v-model="newPassword.password" type="password" name="newPassword" placeholder="New Password" id="newPassword" />
			<label for="login-passwordConfirm">Password Confirm</label>
			<input
			v-model="newPassword.passwordConfirm"
			type="password"
			name="login-passwordConfirm"
			placeholder="Confirm Password"
			id="login-passwordConfirm"
			/>
			<div v-if="errors.length" class="alert alert-danger" role="alert">
				<p v-for="x in errors" :key="x.id">{{ x }}</p>
			</div>
			<button type="submit">Submit New Password</button>
		</form>
	</div>
</template>

<script>
import { ref, reactive, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import axios from 'axios'

const Joi = require('joi')
const registerSchema = Joi.object({
	FullName: Joi.string().min(3).max(100),
	Email: Joi.string().email({ tlds: { allow: false } }),
	phoneNumber: Joi.string().min(11).max(11),
	Password: Joi.string().min(8).max(100),
	CompanyName: Joi.string().allow('').optional(),
})

const loginSchema = Joi.object({
	Email: Joi.string().email({ tlds: { allow: false } }),
	Password: Joi.string()
	.min(8)
	.max(100),
})

const passwordSchema = Joi.object({
	token: Joi.string(),
	password: Joi.string().pattern(new RegExp('^[a-zA-Z0-9]{3,30}$')),
	passwordConfirm: Joi.ref('password'),
})

export default {
	setup() {
		// variables and data
		const errors = ref([])
		const success = ref([])
		const url = window.location.href

		const registerSet = ref(1)

		const registerUserData = reactive({
			FullName: '',
			Email: '',
			phoneNumber: '',
			Password: '',
			CompanyName: '',
		})

		const loginUserData = reactive({
			Email: '',
			Password: '',
		})

		const pwResetBody = reactive({
			email: '',
		})

		const newPassword = reactive({
			token: url.split('?')[1],
			password: '',
			passwordConfirm: '',
		})

		const router = useRouter()
		const currentRouteName = computed(() => useRoute().name)
		const store = useStore()

		// functions
		const register = () => {
			errors.value = []
			const err = registerSchema.validate(registerUserData)
			if (err.error && errors.value.length < 1) {
				errors.value.push(err.error.message)
				return false
			}

			makeApiCall(registerUserData, 'Register')
		}

		const login = () => {
			errors.value = []

			const err = loginSchema.validate(loginUserData)

			if (err.error && errors.value.length < 1) {
				errors.value.push(err.error.message)
				return false
			}

			makeApiCall(loginUserData, 'auth/Login')
		}

		const resetPassword = () => {
			makeApiCall(pwResetBody, 'api/Account/StartPassReset')
			success.value.push('Please check your email for the password reset link. The link is only active for 30 mintues.')
		}

		const newPasswordSubmit = () => {
			errors.value = []

			if (newPassword.password !== newPassword.passwordConfirm) {
				errors.value.push('Passwords must match')
				return false
			}

			let patt = new RegExp(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/)

			if (!patt.test(newPassword.password)) {
				errors.value.push('Please make password more secure.')
				return false
			}
			makeApiCall(newPassword, 'api/Account/EndPassReset')
			router.push('/')
		}

		const makeApiCall = (body, urlEndpoint) => {
			axios({
				method: 'post',
				url: store.state.devUrl + urlEndpoint,
				headers: {
					'content-type': 'application/json',
				},
				data: body,
			})
			.then(result => {
				if (result.data.fullName) {
					store.dispatch('user/userInfo', { name: result.data.fullName })
					localStorage.token = result.data.loginToken
					router.push({ name: 'MyDebts' })
				}
			})
			.catch(err => {
				errors.value.push(err.response.data)
			})
		}

		return {
			errors,
			success,
			currentRouteName,
			registerUserData,
			register,
			registerSet,
			pwResetBody,
			resetPassword,
			useRoute,
			useRouter,
			makeApiCall,
			newPassword,
			newPasswordSubmit,
			passwordSchema,
			store,
			loginUserData,
			login,
		}
	},
}
</script>

<style lang="scss" scoped>
.d-grid{
	display: grid;
}
.middle,
form {
	margin: auto;
	a,
	p {
		color: #293370;
		margin: auto 0px;
	}
	input {
		outline: none;
		border-color: #d7d7d7;
		border-style: solid;
		border-radius: 5px;
		height: 40px;
		margin-bottom: 30px;
		padding: 5px 15px;
	}
	input[type='checkbox'] {
		margin: 0px;
		margin-right: 10px;
	}
	button {
		border: none;
		height: 40px;
		background: #293370;
		color: #fff;
		border-radius: 30px;
		font-weight: 600;
		margin-bottom: 30px;
	}
	.d-flex label:hover {
		cursor: pointer;
	}
	.align {
		display: flex;
		height: 100%;
		justify-content: flex-end;
		align-items: center;
	}
}
</style>
